import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router';

import { menuEntries, MenuEntry } from '../data/menuEntries';

const getDefaultPageId = (items: MenuEntry[]): string => items.find((item) => item.default)?.id || '';

export const usePage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [page, setPage] = useState<string>('');

    const defaultPageId = useMemo(() => getDefaultPageId(menuEntries), []);

    useEffect(() => {
        const nextPageId = searchParams.get('id');
        setPage(nextPageId || defaultPageId);

        if (!nextPageId) {
            setSearchParams({ ...searchParams, id: defaultPageId }, { replace: true });
        }
    }, [searchParams]);

    return page;
};
