import { useRef } from 'react';
import classNames from 'classnames';
import useElementSize from '@rio-cloud/rio-uikit/useElementSize';
import { Outlet } from 'react-router';

import Navigation from '../features/navigation/Navigation';
import MobileNavigation from '../features/navigation/MobileNavigation';
import Footer from '../features/footer/Footer';
import { AppContext } from './AppContext';
// Load iframe resizer here so it does not set the hight for the loader or login screen
import 'iframe-resizer/js/iframeResizer.contentWindow';

const MOBILE_BREAKPOINT = 768;

const PageLayout = () => {
    const wrapperRef = useRef(null);
    const [width] = useElementSize(wrapperRef);

    const showNavigation = !!wrapperRef?.current;
    const isMobile = width <= MOBILE_BREAKPOINT;

    const wrapperClassName = classNames(
        'wrapper display-flex flex-column position-absolute inset-0 bg-white',
        isMobile && 'is-mobile'
    );

    const footerClassName = classNames(
        'position-fixed',
        'left-0',
        'right-0',
        'bottom-0',
        'display-flex',
        'justify-content-between',
        'flex-column-xs',
        'align-items-center-xs',
        'colum-gap-20',
        'row-gap-10',
        'padding-y-10',
        'padding-x-15',
        'border',
        'border-color-lighter',
        'border-top-only',
        'bg-lightest'
    );

    return (
        <AppContext.Provider value={{ isMobile }}>
            <div ref={wrapperRef} style={{ height: '100vh' }} className={wrapperClassName} data-testid="page-layout">
                {showNavigation && isMobile && (
                    <div id="navigation">
                        <MobileNavigation />
                    </div>
                )}
                <div className="display-flex min-width-300 flex-1-1 overflow-y-auto">
                    {showNavigation && !isMobile && (
                        <div id="navigation" className="min-width-300">
                            <Navigation />
                        </div>
                    )}
                    <div id="content" className="flex-1-1">
                        <Outlet />
                    </div>
                </div>
                <div id="footer" className={footerClassName}>
                    <Footer />
                </div>
            </div>
        </AppContext.Provider>
    );
};

export default PageLayout;
